import axiosInstance from './utils';
import { itemStatusValues } from '../model/itemStatus';

export default {
  name: 'users',

  create(item) {
    const clean = this.setDefaultValues(item);
    return axiosInstance.post(`/${this.name}`, clean)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },

  delete(itemId) {
    return axiosInstance.delete(`/${this.name}/${encodeURIComponent(itemId)}`);
  },

  getList(getAll = false) {
    let url = `/${this.name}`;
    if (getAll === true) url += `?${itemStatusValues.getAllUrlParam}`;
    return axiosInstance.get(url)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },

  setDefaultValues(item) {
    const result = { ...item };
    if (item.userIsActive === null) result.userIsActive = true;
    if (item.firstname === null) result.firstname = '';
    if (item.lastname === null) result.lastname = '';
    if (item.role === null) result.role = '';
    return result;
  },

  update(itemId, item) {
    const clean = this.setDefaultValues(item);
    delete clean.id;
    delete clean.userId;
    delete clean.itemStatus;
    delete clean.creationTs;
    delete clean.lastUpdateTs;
    return axiosInstance.put(`/${this.name}/${encodeURIComponent(itemId)}`, clean)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },
};
