/**
 * User schema
 */

import rules from './validationRules';
import i18n from '../i18n';
import { datatypes, getBooleanTypeReferences } from './datatypes';
import { getUserRoleLabel, getUserRoleReferences, INHABITANT } from './userRole';
import { getItemStatusTypeReferences } from './itemStatus';
import { roleAdmin, roleSuperAdmin } from './accessRolesModel';

const userFieldId = {
  name: 'id',
  type: datatypes.string,
  description: 'Unique id of the user',
  label: '',
  allowedRoles: {
    read: [],
    write: [],
  },
};

const userFieldCompanyId = {
  name: 'companyId',
  type: datatypes.string,
  description: 'Id of the company the user is associated to.',
  label: '',
  allowedRoles: {
    read: [],
    write: [],
  },
  rules: [rules.required],
};

const userFieldCompanyName = {
  name: 'companyName',
  type: datatypes.string,
  description: 'Name of the company the user is associated to.',
  minLength: 1,
  maxLength: 96,
  label: i18n.tc('user.fields.companyName', 1),
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
  rules: [rules.required],
};

const userFieldCompanyIsActive = {
  name: 'companyIsActive',
  type: datatypes.boolean,
  description: 'The user is currently active.',
  label: i18n.tc('user.fields.companyIsActive', 1),
  references: getBooleanTypeReferences(),
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [],
  },
  rules: [],
};

const userFieldEmail = {
  name: 'email',
  type: datatypes.string,
  description: 'email of the user',
  minLength: 1,
  maxLength: 128,
  label: i18n.tc('user.fields.email', 1),
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [],
  },
  rules: [rules.required, rules.email],
};

const userFieldFirstname = {
  name: 'firstname',
  type: datatypes.string,
  description: 'firstname of the user',
  minLength: 1,
  maxLength: 128,
  label: i18n.tc('user.fields.firstname', 1),
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
  rules: [rules.required],
};

const userFieldLastname = {
  name: 'lastname',
  type: datatypes.string,
  description: 'lastname of the user',
  minLength: 0,
  maxLength: 128,
  label: i18n.tc('user.fields.lastname', 1),
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
  rules: [rules.required],
};

const userFieldRole = {
  name: 'role',
  type: datatypes.string,
  description: 'role of the user',
  minLength: 0,
  maxLength: 128,
  label: i18n.tc('user.fields.role', 1),
  references: getUserRoleReferences(),
  default: INHABITANT,
  valueFormat: {
    method: getUserRoleLabel,
  },
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [],
  },
  rules: [rules.isUserRole],
};

const userFieldUserIsActive = {
  name: 'userIsActive',
  type: datatypes.boolean,
  description: 'The user is currently active.',
  label: i18n.tc('user.fields.userIsActive', 1),
  references: getBooleanTypeReferences(),
  default: true,
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
  rules: [],
};

const userFieldItemStatus = {
  name: 'itemStatus',
  type: datatypes.itemStatus,
  description: 'Etat de l élément.',
  label: i18n.tc('user.fields.itemStatus', 1),
  references: getItemStatusTypeReferences(),
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [],
  },
  rules: [],
};

const user = {
  fields: [
    userFieldId,
    userFieldCompanyName,
    userFieldFirstname,
    userFieldLastname,
    userFieldEmail,
    userFieldRole,
    userFieldUserIsActive,
    userFieldCompanyId,
    userFieldCompanyIsActive,
    userFieldItemStatus,
  ],
  crudAllowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    readInactive: [roleAdmin, roleSuperAdmin],
    create: [roleAdmin, roleSuperAdmin],
    update: [roleAdmin, roleSuperAdmin],
    delete: [roleSuperAdmin],
  },
};

export default user;
export {
  user,
  userFieldId,
  userFieldCompanyId,
  userFieldCompanyName,
  userFieldCompanyIsActive,
  userFieldUserIsActive,
  userFieldEmail,
  userFieldFirstname,
  userFieldLastname,
  userFieldRole,
  userFieldItemStatus,
};
