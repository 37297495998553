import axiosInstance from './utils';
import { itemStatusValues } from '../model/itemStatus';

export default {
  name: 'companies',

  setDefaultValues(item) {
    const result = { ...item };
    if (item.companyIsActive === null) result.companyIsActive = true;
    if (item.phoneNumber === null) result.phoneNumber = '';
    if (item.logo === null) result.logo = '';
    try {
      result.privateParams = JSON.parse(JSON.stringify(item.privateParams));
    } catch (error) { // TODO : test and adapt according to error type
      result.privateParams = item.privateParams;
    }
    try {
      result.publicParams = JSON.parse(JSON.stringify(item.publicParams));
    } catch (error) { // TODO : test and adapt according to error type
      result.publicParams = item.publicParams;
    }
    if (item.itemStatus === null) result.itemStatus = itemStatusValues.default;
    return result;
  },

  getList(getAll = false) {
    let url = `/${this.name}`;
    if (getAll === true) url += `?${itemStatusValues.getAllUrlParam}`;
    return axiosInstance.get(url)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },

  getItem(itemId) {
    if (itemId) throw Error('companies.api.getItem : Never tested method. Please test before use.');
    return axiosInstance.get(`/${this.name}/${encodeURIComponent(itemId)}`)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },

  create(item) {
    const clean = this.setDefaultValues(item);
    return axiosInstance.post(`/${this.name}`, clean)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },

  update(itemId, item) {
    const clean = this.setDefaultValues(item);
    delete clean.companyId;
    delete clean.id;
    delete clean.itemStatus;
    delete clean.creationTs;
    delete clean.lastUpdateTs;
    delete clean.logo; // TODO : auhtorize logo management
    return axiosInstance.put(`/${this.name}/${encodeURIComponent(itemId)}`, clean)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },
};
