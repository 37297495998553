<template>
  <v-dialog
    v-model="isBadgeUserDialogOpen"
    persistent
    max-width="75%"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="isBadgeUserDialogValid" dense>
            <v-row>
              <v-col cols="12" sm="3" md="3" dense >
                <v-subheader>{{ userSectionLabel }}</v-subheader>
              </v-col>
              <v-col cols="12" sm="3" md="3" dense >
                <v-text-field
                  v-model="userItem[userFieldFirstname.name]"
                  :label="userFieldFirstname.label"
                  :rules="userFieldFirstname.rules"
                  :readonly="true"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" dense >
                <v-text-field
                  v-model="userItem[userFieldLastname.name]"
                  :label="userFieldLastname.label"
                  :rules="userFieldLastname.rules"
                  :readonly="true"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" dense >
                <v-text-field
                  v-model="userItem[userFieldCompanyName.name]"
                  :label="userFieldCompanyName.label"
                  :rules="userFieldCompanyName.rules"
                  :readonly="true"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="3" md="3" dense >
                <v-subheader>{{ badgeSectionLabel }}</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="6" dense >
                <v-text-field
                  v-model="badgeMacAddress"
                  :label="badgeFieldMacAddress.label"
                  :rules="badgeFieldMacAddress.rules"
                  :readonly="false"
                  :hint="scanQrCodesHint"
                  persistent-hint
                  dense
                  clearable
                  clear-icon="mdi-eraser"
                ></v-text-field>
                <v-card class="ma-2" v-if="unknownBadgeWarningMessage">
                  <v-card-text
                  v-html="unknownBadgeWarningMessage"
                  class="error">
                  </v-card-text>
                </v-card>
                <v-card class="ma-2" v-if="badgePreviousHolderWarningMessage">
                  <v-card-text
                  v-html="badgePreviousHolderWarningMessage"
                  class="warning">
                  </v-card-text>
                </v-card>
                <v-card class="ma-2" v-if="badgeIsInactiveWarningMessage">
                  <v-card-text
                  v-html="badgeIsInactiveWarningMessage"
                  class="error">
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="3" md="3" dense class="d-flex justify-center">
                <v-btn  v-if="(crudAccess.create || crudAccess.update )&& !scanQrCode"
                  dark
                  fab
                  class="mx-4"
                  color="primary"
                  @click="scanQrCode = true"
                  >
                  <v-icon large left class="ma-2"> mdi-qrcode-scan</v-icon>
                </v-btn>
                <div v-if="scanQrCode">
                  <qrcode-stream @decode="onDecode"></qrcode-stream>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="userIsChangingBadge">
              <v-col cols="12" sm="3" md="3" dense ></v-col>
              <v-col cols="12" sm="9" md="9" dense >
                 <v-checkbox
                   v-model="userIsChangingBadgeConfirm"
                   :label="userIsChangingBadgeConfirmLabel"
                   :rules="userIsChangingBadgeConfirmRules"
                 ></v-checkbox>
                  <v-btn v-if="userIsChangingBadge"
                    outlined color="blue darken-1"
                    @click="closePreviouseBadgeUserForUserBtnHandler"
                  >
                    <v-icon left dark>mdi-calendar-end</v-icon>
                    {{ closePreviouseBadgeUserForUserButtonLabel }}
                  </v-btn>

              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text color="blue darken-1"
          @click="closeBadgeUserForm"
        >
          {{ $t("appButton.cancel") }}
        </v-btn>
        <v-btn
          text color="blue darken-1"
          @click="save"
          :disabled="!isBadgeUserDialogValid"
        >
          {{ $t("appButton.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  badgeUser,
  // badgeUserFieldBadgeId,
  badgeUserFieldUserId,
  badgeUserFieldStartTs,
  badgeUserFieldStopTs,
  badgeUserFieldBadgeIsActive,
  badgeUserFieldMacAddress,
  badgeUserFieldUuid,
  badgeUserFieldRfidTagId,
  badgeUserFieldEmail,
  badgeUserFieldFirstname,
  badgeUserFieldLastname,
  badgeUserFieldUserIsActive,
  badgeUserFieldCompanyId,
  badgeUserFieldCompanyName,
  badgeUserFieldCompanyIsActive,
} from '@/model/badgeuser';
import {
  badgeFieldMacAddress,
} from '@/model/badge';
import {
  userFieldFirstname,
  userFieldLastname,
  userFieldCompanyName,
} from '@/model/user';
import {
  genericValues,
} from '@/model/genericValues';
import { QrcodeStream } from 'vue-qrcode-reader';
import { mapGetters } from 'vuex';
import { defaultCrudAccess, getAllowedCrudForUser } from '../model/accessRolesModel';
import i18n from '../i18n';
import badgesApi from '../api/badges.api';
import dbAPI from '../api/badgesusers.api';

export default {
  name: 'BadgeUserDialogForUser',
  components: {
    QrcodeStream,
  },
  data: () => ({
    tablename: 'badgeuser',
    badgeMacAddress: '',
    badgeSectionLabel: i18n.tc('badge.label', 2),
    crudAccess: defaultCrudAccess,
    crudLabels: {},
    isBadgeUserDialogValid: true,
    isPreviouseBadgeUserForBadgeUpdated: false,
    isPreviouseBadgeUserForUserUpdated: false,
    loading: true,
    newBadgeUser: null,
    previouseBadgeUserForUser: null,
    selectedBadge: null,
    badgePreviousHolderWarningMessage: '',
    badgeIsInactiveWarningMessage: '',
    unknownBadgeWarningMessage: '',
    refBadgeList: [],
    scanQrCode: false,
    scanQrCodesHint: i18n.tc('badge.scanQrCode', 1),
    userIsChangingBadge: false,
    userIsChangingBadgeConfirm: false,
    userIsChangingBadgeConfirmLabel: i18n.t('badgeuser.userIsChangingBadgeConfirmation'),
    userIsChangingBadgeConfirmRules: [
      (v) => !!v || i18n.t('badgeuser.userIsChangingBadgeConfirmationMissing'),
    ],
    userSectionLabel: i18n.tc('user.label', 1),
  }),

  props: {
    isBadgeUserDialogOpen: {
      type: Boolean,
      default: false,
    },
    userItem: {
      type: Object,
    },
    badgeUserRecords: {
      type: Array,
      default: () => ([]),

    },
  },

  created() {
    this.crudAccess = getAllowedCrudForUser(badgeUser.crudAllowedRoles, this.userRoles);
    if (!this.crudAccess.read) {
      const msg = this.$t('errMsg.forbiddenAccess_fmt',
        { operation: this.$t('crudLabels.read').toLowerCase() });
      this.$store.dispatch('displayAlert', msg);
      this.$router.go(-1);
    }
    this.refreshData();
  },

  watch: {
    $route() {
      this.refreshData();
    },

    badgeMacAddress() {
      this.previouseBadgeUserForBadge = null;
      this.selectedBadge = null;
      this.badgePreviousHolderWarningMessage = '';
      this.badgeIsInactiveWarningMessage = '';
      this.unknownBadgeWarningMessage = '';
      // Check if the selected badge exists and is active
      if (this?.badgeMacAddress) {
        this.badgeMacAddress = this.badgeMacAddress.toLowerCase();
        const found = this.refBadgeList.find((b) => (b.macAddress === this.badgeMacAddress));
        if (found) {
          this.selectedBadge = found;
          if (!this.selectedBadge.badgeIsActive) {
            this.badgeIsInactiveWarningMessage = i18n.t('badgeuser.badgeIsInactive_HtmlFmt',
              { badge: this.badgeMacAddress });
          }
        } else {
          this.unknownBadgeWarningMessage = i18n.t('badgeuser.unknownBadge_Fmt',
            { badge: this.badgeMacAddress });
        }
      }
      // Check if a previous badgeuser record exits
      if (this?.badgeMacAddress && this?.userItem?.userId) {
        const now = Math.floor(Date.now() / 1000);
        const found = this.badgeUserRecords.find((bu) => (bu.macAddress === this.badgeMacAddress
                                                          && (bu.startTs <= now)
                                                          && (now < bu.stopTs)));
        this.previouseBadgeUserForBadge = found;
        if (found && found?.userId !== this?.userItem?.userId) {
          const badgePreviousHolder = `${found.firstname} ${found.lastname} (${found.companyName})`;
          this.badgePreviousHolderWarningMessage = i18n.t('badgeuser.badgePreviousHolderWarningMessage_HtmlFmt',
            {
              badge: this.badgeMacAddress,
              previous: badgePreviousHolder,
              next: `${this?.userItem?.firstname} ${this?.userItem?.lastname} (${this?.userItem?.companyName})`,
            });
        }
      }
    },

    userItem() {
      this.badgeMacAddress = '';
      this.userIsChangingBadge = false;
      this.userIsChangingBadgeConfirm = false;
      this.previouseBadgeUserForBadge = null;
      this.previouseBadgeUserForUser = null;
      this.selectedBadge = null;
      this.badgePreviousHolderWarningMessage = '';
      this.badgeIsInactiveWarningMessage = '';
      this.unknownBadgeWarningMessage = '';
      if (this?.userItem?.userId) {
        const now = Math.floor(Date.now() / 1000);
        const found = this.badgeUserRecords.find((bu) => (bu.userId === this.userItem.userId
                                                          && (bu.startTs <= now)
                                                          && (now < bu.stopTs)));
        if (found) {
          this.badgeMacAddress = found.macAddress;
          this.userIsChangingBadge = true;
          this.previouseBadgeUserForUser = found;
        }
      }
    },

    isPreviouseBadgeUserForBadgeUpdated(newVal) {
      if (newVal) {
        this.performApiCallForPreviouseBadgeUserForUser();
      }
    },

    isPreviouseBadgeUserForUserUpdated(newVal) {
      if (newVal) {
        this.performApiCallForNewBadgeUserAndClose();
      }
    },

  },

  computed: {
    ...mapGetters([
      'userRoles',
    ]),

    title() { return i18n.tc('badgeuser.label', 1); },
    badgeFieldMacAddress() { return badgeFieldMacAddress; },
    userFieldCompanyName() { return userFieldCompanyName; },
    userFieldFirstname() { return userFieldFirstname; },
    userFieldLastname() { return userFieldLastname; },
    closePreviouseBadgeUserForUserButtonLabel() {
      return i18n.t('badgeuser.closeCurrentBadgeAttribution_fmt',
        { macAddress: this.previouseBadgeUserForUser.macAddress });
    },
  },

  methods: {
    closeBadgeUserForm() {
      this.$emit('update:isBadgeUserDialogOpen', false);
      this.$nextTick(() => {
        this.resetContext();
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      });
    },

    closePreviouseBadgeUserForUserBtnHandler() {
      const now = Math.floor(Date.now() / 1000);
      if (this.previouseBadgeUserForUser) {
        this.previouseBadgeUserForUser.stopTs = now;
        this.loading = true;
        this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.runningMsg_Fmt`, 1,
          { operation: this.$t('crudLabels.updating') }));
        this.$nextTick(() => {
          this.performApiCallForPreviouseBadgeUserForUser(true);
        });
      } else {
        throw Error('Unable to get the expected BadgeUser item... Please contact your app provider.');
      }
    },

    getRefBadgeList() {
      this.loading = true;
      this.$nextTick(() => {
        badgesApi.getList(true).then((response) => {
          this.refBadgeList = response.data.data;
        }).catch((error) => {
          const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 2,
            { operation: this.$t('crudLabels.read').toLowerCase() });
          const apiErrorMsg = error?.response?.data?.error_msg || '';
          console.error(`Error : ${msg}`, error, apiErrorMsg);
          this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
        }).then(() => {
          this.loading = false;
        });
      });
    },

    onDecode(evt) {
      this.badgeMacAddress = evt.toLowerCase();
      this.scanQrCode = false;
    },

    performApiCallForNewBadgeUserAndClose() {
      dbAPI.create(this.newBadgeUser)
        .then((data) => {
          if (data.status === 201) {
            this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.doneMsg_Fmt`, 1,
              { operation: this.$t('crudLabels.created') }));
          }
          this.badgeUserRecords.push(this.newBadgeUser);
        })
        .catch((error) => {
          const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 1,
            { operation: i18n.t('crudLabels.creation').toLowerCase() });
          const apiErrorMsg = error?.response?.data?.error_msg || '';
          console.error(`Error : ${msg}`, error, apiErrorMsg);
          this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
        }).then(() => {
          // Exit control (we are asynchronous here)
          this.loading = false;
          this.closeBadgeUserForm();
        });
    },

    performApiCallForPreviouseBadgeUserForBadge() {
      let updateDone = false;
      if (this.previouseBadgeUserForBadge) {
        if (!this.previouseBadgeUserForBadge?.id) {
          this.previouseBadgeUserForBadge.id = `${this.previouseBadgeUserForBadge.pK}:${this.previouseBadgeUserForBadge.sK}`;
        }
        dbAPI.update(this.previouseBadgeUserForBadge.id, this.previouseBadgeUserForBadge)
          .then((data) => {
            if (data.status === 201) {
              updateDone = true;
              this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.doneMsg_Fmt`, 1,
                { operation: this.$t('crudLabels.updated') }));
            }
          })
          .catch((error) => {
            const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 1,
              { operation: this.$t('crudLabels.update').toLowerCase() });
            const apiErrorMsg = error?.response?.data?.error_msg || '';
            console.error(`Error : ${msg}`, error, apiErrorMsg);
            this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
          }).then(() => {
            // trigger next update
            this.isPreviouseBadgeUserForBadgeUpdated = updateDone;
          });
      } else {
        this.isPreviouseBadgeUserForBadgeUpdated = true;
      }
    },

    performApiCallForPreviouseBadgeUserForUser(closeWhenDone = false) {
      let updateDone = false;
      if (this.previouseBadgeUserForUser) {
        if (!this.previouseBadgeUserForUser?.id) {
          this.previouseBadgeUserForUser.id = `${this.previouseBadgeUserForUser.pK}:${this.previouseBadgeUserForUser.sK}`;
        }
        dbAPI.update(this.previouseBadgeUserForUser.id, this.previouseBadgeUserForUser)
          .then((data) => {
            if (data.status === 201) {
              updateDone = true;
              this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.doneMsg_Fmt`, 1,
                { operation: this.$t('crudLabels.updated') }));
            }
          })
          .catch((error) => {
            const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 1,
              { operation: this.$t('crudLabels.update').toLowerCase() });
            const apiErrorMsg = error?.response?.data?.error_msg || '';
            console.error(`Error : ${msg}`, error, apiErrorMsg);
            this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
          }).then(() => {
            if (closeWhenDone) this.closeBadgeUserForm();
            else {
              // trigger next update
              this.isPreviouseBadgeUserForUserUpdated = updateDone;
            }
          });
      } else {
        // trigger next update
        this.isPreviouseBadgeUserForUserUpdated = true;
      }
    },

    refreshData() {
      this.crudAccess = getAllowedCrudForUser(badgeUser.crudAllowedRoles, this.userRoles);
      this.setCrudLabels();
      this.resetContext();
      this.getRefBadgeList();
    },

    resetContext() {
      this.userIsChangingBadge = false;
      this.userIsChangingBadgeConfirm = false;
      this.badgeMacAddress = '';
      this.newBadgeUser = null;
      this.previouseBadgeUserForBadge = null;
      this.previouseBadgeUserForUser = null;
      this.selectedBadge = null;
      this.badgePreviousHolderWarningMessage = '';
      this.badgeIsInactiveWarningMessage = '';
      this.unknownBadgeWarningMessage = '';
      this.scanQrCode = false;
      this.userIsChangingBadge = false;
      this.userIsChangingBadgeConfirm = false;
    },

    save() {
      if (!this.selectedBadge) {
        this.$store.dispatch('displayAlert', i18n.t('badgeuser.unknownBadge_Fmt',
          { badge: this.badgeMacAddress }));
        return;
      }
      if (!this.selectedBadge.badgeIsActive) {
        this.$store.dispatch('displayAlert', i18n.t('badgeuser.badgeIsInactive_HtmlFmt',
          { badge: this.badgeMacAddress }));
        return;
      }
      // Check if update means something, otherwise get out.
      // This is no use to perform the same test with this.previouseBadgeUserForUser
      if (this.previouseBadgeUserForBadge
        && this.previouseBadgeUserForBadge.userId === this.userItem.userId) {
        this.$store.dispatch('displayInfo', this.$t('badgeuser.sameBadgeSameUser'));
        this.closeBadgeUserForm();
        return;
      }
      // Do the real job : initialization / updates
      const now = Math.floor(Date.now() / 1000);
      // update previouseBadgeUserForBadge
      if (this.previouseBadgeUserForBadge) {
        this.previouseBadgeUserForBadge.stopTs = now;
      }
      // update previouseBadgeUserForUser
      if (this.previouseBadgeUserForUser) {
        this.previouseBadgeUserForUser.stopTs = now;
      }
      // Create new badgeUser item
      this.newBadgeUser = this.setNewBadgeUser(this.selectedBadge, this.userItem,
        now, genericValues.undefinedStopTs);
      // Go Write to API
      this.sendUpdatesToApiThenClose(this.previouseBadgeUser, this.newBadgeUser);
    },

    sendUpdatesToApiThenClose() {
      this.isPreviouseBadgeUserForBadgeUpdated = false;
      this.isPreviouseBadgeUserForUserUpdated = false;
      this.loading = true;
      this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.runningMsg_Fmt`, 1,
        { operation: this.$t('crudLabels.updating') }));
      this.$nextTick(() => {
        // Perform the optional updated item from the badge side, then calls the next update
        this.performApiCallForPreviouseBadgeUserForBadge();
        // Perform the optional updated item from the user side, then calls the next update
        // this.performApiCallForPreviouseBadgeUserForUser();
        // Call the insert API, then close
        // this.performApiCallForNewBadgeUserAndClose();
      });
    },

    setNewBadgeUser(badgeItem, userItem, startTs, stopTs) {
      const result = {};
      result.pK = badgeItem.id;
      result.sK = `${userItem.userId}:${startTs}`;
      result[badgeUserFieldUserId.name] = userItem.userId;
      result[badgeUserFieldStartTs.name] = startTs;
      result[badgeUserFieldStopTs.name] = stopTs;
      result[badgeUserFieldMacAddress.name] = badgeItem.macAddress;
      result[badgeUserFieldUuid.name] = badgeItem.uuid;
      result[badgeUserFieldRfidTagId.name] = badgeItem.rfidTagId;
      result[badgeUserFieldBadgeIsActive.name] = badgeItem.badgeIsActive;
      result[badgeUserFieldEmail.name] = userItem.email;
      result[badgeUserFieldFirstname.name] = userItem.firstname;
      result[badgeUserFieldLastname.name] = userItem.lastname;
      result[badgeUserFieldUserIsActive.name] = userItem.userIsActive;
      result[badgeUserFieldCompanyId.name] = userItem.companyId;
      result[badgeUserFieldCompanyName.name] = userItem.companyName;
      result[badgeUserFieldCompanyIsActive.name] = userItem.companyIsActive;
      return result;
    },

    setCrudLabels() {
      this.crudLabels = {
        create: i18n.t(`${this.tablename}.c`),
        retrieve: i18n.tc(`${this.tablename}.r`, 1),
        update: i18n.tc(`${this.tablename}.u`, 1),
        delete: i18n.tc(`${this.tablename}.d`, 1),
        list: i18n.tc(`${this.tablename}.list`, 1),
        confirmDelete: i18n.tc(`${this.tablename}.confirmD`, 1),
      };
    },
  },
};
</script>
