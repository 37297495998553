import axiosInstance from './utils';
import { itemStatusValues } from '../model/itemStatus';

export default {
  name: 'badges',

  setDefaultValues(item) {
    const result = { ...item };
    if (item.badgeIsActive === null) result.badgeIsActive = true;
    result.macAddress = item.macAddress.toLowerCase();
    result.uuid = item.uuid.toLowerCase();
    result.rfidTagId = item.rfidTagId.toLowerCase();
    try {
      result.privateParams = JSON.parse(JSON.stringify(item.privateParams));
    } catch (error) { // TODO : test and adapt according to error type
      result.privateParams = item.privateParams;
    }
    if (item.itemStatus === null) result.itemStatus = itemStatusValues.default;
    return result;
  },

  getList(getAll = false) {
    let url = `/${this.name}`;
    if (getAll === true) url += `?${itemStatusValues.getAllUrlParam}`;
    return axiosInstance.get(url)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },

  create(item) {
    const clean = this.setDefaultValues(item);
    return axiosInstance.post(`/${this.name}`, clean)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },

  update(itemId, item) {
    const clean = this.setDefaultValues(item);
    delete clean.id;
    // delete clean.macAddress; // Read Only but required by the API Model => DON'T DELETE IT !
    delete clean.itemStatus;
    delete clean.creationTs;
    delete clean.lastUpdateTs;
    return axiosInstance.put(`/${this.name}/${encodeURIComponent(itemId)}`, clean)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },
};
